.button {
  width: 100%;
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 15px;
}

.button-big {
  height: 80px !important;
}

.button-disabled {
  cursor: default;
  background-color: #D3D3D3 !important;
}

.button-count {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  color: #01b6d1;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 800;
}

.button-active {
  /* box-shadow: 2px 3px 5px #999; */
  cursor: pointer;
}

.button-primary {
  background-color: #01b6d1;
  color: #fff;
}

.button-secondary {
  border: 1px solid #01b6d1;
  color: #01b6d1
}

.l1 {
  margin: 0px;
  padding: 0px;
  font-size: 25px;
}

.l2 {
  margin: 0px;
}