.header {
  height: 100px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #01b6d1;
  color: #fff;
  box-shadow: 0px 0px 5px #000;
  margin-bottom: 30px;
}

h1 {
  margin: 0px;
  font-weight: 400;
  font-size: 30px;
}

h2 {
   margin: 0px;
   font-weight: 300;
   font-size: 20px;
}