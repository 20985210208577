body {
  margin: 0;
  font-family: "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.full-height {
  height: 100%;
}

.app-container {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}


.container {
  max-width: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--spectrum-global-color-gray-100);
}

.form-element {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

input, button {
  padding: 0;
  width: 100%;
  border-radius: 10px;
  font-size: 30px;
  background: #fff;
  border: 1px solid #01b6d1;
  box-sizing: border-box;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
  text-indent: 5px;
}

button.small-button {
  width: 130px;
  height: 40px;
  margin: 0;
}

input {
  color: #838383;
  height: 50px;
}

button {
  height: 60px;
  background-color: #01b6d1;
  color: #fff;
  font-size: 25px;
}

label {
  font-size: 20px;
  font-weight: lighter;
}

h3 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 5px;
}

hr {
  border-top: 1px solid #01b6d1;
  width: 100%;
}

.error-message {
  margin-top: 20px;
  color: red;
}

.appointment-details-container {
  margin-right: 40px;
}

.appointment-details {
  margin-top: 20px;
}

.appointment-details-text {
  margin-top: 5px;
  font-weight: 400;
  text-indent: 20px;
  margin-bottom: 0px;
}

.appointment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.appointment-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.appointment-details {
  margin: 0;
  margin-right: 20px;
}

.row {
  padding: 0px;
  margin: 0px;
}
